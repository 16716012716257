import React from 'react';
import ArrayStorage from '@page-components/open-source/SubPages/ArrayStorage';
import OpenSourcePageWrapper from '@page-components/open-source/OpenSourcePageWrapper';

const OpenSourcePage = () => {
  return (
    <OpenSourcePageWrapper>
      <ArrayStorage />
    </OpenSourcePageWrapper>
  );
};

export default OpenSourcePage;
