import './ArrayStorage.scss';
import React from 'react';
import links from '@constants/links';
import Typography from '@components/Typography';
import Button from '@components/Button';
import Title from '../components/Title';
import Card from '../components/Card';
import GithubIcon from '../../assets/github.svg';
import Buttons from '../components/Card/components/Buttons';
import CheckIcon from './assets/check.svg';
import PythonIcon from './assets/python.svg';
import RIcon from './assets/r.svg';
import JavaIcon from './assets/java.svg';
import CLIIcon from './assets/cli.svg';
import CSharpIcon from './assets/csharp.svg';
import NodejsIcon from './assets/nodejs.svg';
import GOIcon from './assets/go.svg';
import CPlusPlusIcon from './assets/cplusplus.svg';
import CIcon from './assets/c.svg';
import ReferenceIcon from './assets/reference.svg';

interface APIProps {
  text: string;
  link: string;
  referenceLink?: string;
  icon: string;
  variant: string;
}

const API: React.FC<APIProps> = (props) => {
  const { text, link, icon, variant, referenceLink } = props;

  return (
    <div className="TDB-OpenSourcePage__ArrayStorage__APIWrapper">
      <a className="TDB-OpenSourcePage__ArrayStorage__API" href={link} target="_blank" rel="noreferrer">
        <div className="TDB-OpenSourcePage__ArrayStorage__API__background">
          <div className={`TDB-OpenSourcePage__ArrayStorage__API__blur TDB-OpenSourcePage__ArrayStorage__API__blur--${variant}`} />
        </div>
        <div className="TDB-OpenSourcePage__ArrayStorage__API__inner">
          <div className="TDB-OpenSourcePage__ArrayStorage__API__content">
            <img src={icon} alt="" className="TDB-OpenSourcePage__ArrayStorage__API__icon" />
            <Typography
              as="p"
              className="TDB-OpenSourcePage__ArrayStorage__API__text"
              color="brand-100"
              fontSize="subheading-2"
              fontWeight="semi-bold"
            >
              {text}
            </Typography>
          </div>
          <div className="TDB-OpenSourcePage__ArrayStorage__API__links">
            {referenceLink && (
              <a target="_blank" rel="noreferrer" href={referenceLink} className="TDB-OpenSourcePage__ArrayStorage__API__links__link">
                <img src={ReferenceIcon} alt="" />
              </a>
            )}

            <div className="TDB-OpenSourcePage__ArrayStorage__API__links__link">
              <img src={GithubIcon} alt="" />
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

const Feature: React.FC = (props) => {
  const { children } = props;
  return (
    <div className="TDB-OpenSourcePage__ArrayStorage__feature">
      <img src={CheckIcon} alt="check" className="TDB-OpenSourcePage__ArrayStorage__feature__icon" />
      <Typography fontSize="body-lg" fontWeight="medium" className="TDB-OpenSourcePage__ArrayStorage__feature__text">
        {children}
      </Typography>
    </div>
  );
};

const ArrayStorage = () => {
  return (
    <div>
      <Title>Array Storage</Title>
      <Card
        className="TDB-OpenSourcePage__ArrayStorage__card"
        title="TileDB Embedded"
        description="A C++ library for storing and accessing any complex data based on multi-dimensional arrays."
        gradientColor="#0058f8"
      >
        <div className="TDB-OpenSourcePage__ArrayStorage__buttons-wrapper">
          <Button primary to={links.embedded}>
            Explore more
          </Button>
          <Buttons
            className="TDB-OpenSourcePage__ArrayStorage__buttons"
            items={[{ text: 'View on Github', icon: GithubIcon, link: links.core }]}
          />
        </div>
        <div className="TDB-OpenSourcePage__ArrayStorage__features">
          <Typography
            as="h6"
            color="neutral-main"
            fontWeight="bold"
            fontSize="body-lg"
            className="TDB-OpenSourcePage__ArrayStorage__features__title"
          >
            FEATURES
          </Typography>
          <div className="TDB-OpenSourcePage__ArrayStorage__features__content">
            <div className="TDB-OpenSourcePage__ArrayStorage__features__content__list">
              <Feature>Support for both dense and sparse arrays</Feature>
              <Feature>Support for dataframes and key-value stores</Feature>
              <Feature>Optimized for object stores (AWS S3, Google Cloud Storage, Azure Blob Storage)</Feature>
              <Feature>Chunked (tiled) arrays</Feature>
            </div>
            <div className="TDB-OpenSourcePage__ArrayStorage__features__content__list">
              <Feature>Tiling and compression</Feature>
              <Feature>Parallel IO</Feature>
              <Feature>Data versioning (rapid updates, time traveling)</Feature>
              <Feature>Groups</Feature>
              <Feature>Arbitrary metadata</Feature>
            </div>
          </div>
        </div>
      </Card>
      <Title>APIs</Title>
      <div className="TDB-OpenSourcePage__ArrayStorage__apis">
        <div className="TDB-OpenSourcePage__ArrayStorage__apis__list">
          <API
            text="TileDB-C++"
            icon={CPlusPlusIcon}
            referenceLink="https://tiledb-inc-tiledb.readthedocs-hosted.com/en/stable/c++-api.html"
            link={links.github.tiledbCPlusPlus}
            variant="c++"
          />
          <API
            text="TileDB-Py"
            icon={PythonIcon}
            referenceLink="https://tiledb-inc-tiledb.readthedocs-hosted.com/projects/tiledb-py/en/stable/python-api.html"
            link={links.github.tiledbPy}
            variant="py"
          />
          <API
            text="TileDB-Java"
            icon={JavaIcon}
            referenceLink="https://www.javadoc.io/doc/io.tiledb/tiledb-java/latest/index.html"
            link={links.github.tiledbJava}
            variant="java"
          />
          <API
            text="TileDB-CSharp"
            icon={CSharpIcon}
            referenceLink="https://tiledb-inc.github.io/TileDB-CSharp/api/TileDB.CSharp.html"
            link={links.github.tiledbCSharp}
            variant="c-sharp"
          />
          <API
            text="TileDB-CLI"
            icon={CLIIcon}
            referenceLink="https://docs.tiledb.com/main/integrations-and-extensions/genomics/population-genomics/api-reference/cli"
            link={links.github.tiledbCLI}
            variant="cli"
          />
        </div>
        <div className="TDB-OpenSourcePage__ArrayStorage__apis__list">
          <API
            text="TileDB-C"
            icon={CIcon}
            referenceLink="https://tiledb-inc-tiledb.readthedocs-hosted.com/en/stable/c-api.html"
            link={links.github.tiledbC}
            variant="c"
          />
          <API
            text="TileDB-R"
            icon={RIcon}
            referenceLink="https://tiledb-inc.github.io/TileDB-R/reference/index.html"
            link={links.github.tiledbR}
            variant="r"
          />
          <API
            text="TileDB-Go"
            icon={GOIcon}
            referenceLink="https://godoc.org/github.com/TileDB-Inc/TileDB-Go"
            link={links.github.tiledbGO}
            variant="go"
          />
          <API text="TileDB-Cloud-JS" icon={NodejsIcon} link={links.github.tiledbCloudJS} variant="js" />
        </div>
      </div>
    </div>
  );
};

export default ArrayStorage;
